import ImageKit from 'imagekit-javascript'
import React from 'react'

import { Link } from '@remix-run/react'
import P2 from '~/components/typography/P2'
import useGlobal from '~/hooks/useGlobal'
import useIsMobile from '~/hooks/useIsMobile'
import useUserData from '~/hooks/useUserData'
import type { Language } from '~/i18n.universal'
import IconAI from '~/static/svg/IconAI'
import urlReverse from '~/utils/urlReverse'
import P3 from '../typography/P3'

interface Props {
  lang: Language
  trans: {
    BetaAccessExclusiveForTradeUsers: String
    TheArtlingDiscoverySearch: String
    TheArtlingAIDiscoverySearch: String
  }
  className?: string
  BGImg?: string
}

const AIStrip: React.FC<Props> = ({ className, lang, trans, BGImg }) => {
  const global = useGlobal()
  const { isTradeUser } = useUserData()
  const { isMobile } = useIsMobile()

  if (!global || !isTradeUser || isMobile) {
    return null
  }

  const ikClient = new ImageKit({
    urlEndpoint: global.ENV.IMAGEKIT_PATH || '',
  })
  const path = ikClient.url({
    urlEndpoint: global?.ENV.IMAGEKIT_PATH,
    path: BGImg || '',
  })

  return (
    <Link to={urlReverse('aiSearch', { lang: 'en' })}>
      <div
        className={
          'relative flex h-[40px] w-full items-center justify-between rounded-lg bg-cover px-6 ' +
          (className ? ` ${className}` : '')
        }
        style={{ backgroundImage: `url(${path})` }}
      >
        <div className="flex flex-row items-center justify-center">
          <div className="mr-1 h-2 w-2 rounded-full bg-white" />
          <P3 className="font-bold uppercase text-white">
            {trans.BetaAccessExclusiveForTradeUsers}
          </P3>
        </div>
        <P2 className="absolute right-0 left-0 mx-auto w-max-content text-white">
          {trans.TheArtlingDiscoverySearch}
        </P2>
        <div className="flex flex-row items-center justify-center">
          <IconAI
            fill="#ffffff"
            width="12px"
            className="mr-2"
          />
          <P3 className="font-bold uppercase text-white">
            {trans.TheArtlingAIDiscoverySearch}
          </P3>
        </div>
      </div>
    </Link>
  )
}

export default AIStrip
